<template>
  <div>
    <div v-if="isSubtitle" class='message-wrapper message-wrapper--subheading'>
      <div class='message message--dateandsubtitlemessage' v-html="chatLine.text"></div>
    </div>

    <div v-if="isMyTutorText" class='message-wrapper' v-bind:class="{'message-wrapper--quiz-question' : questionInQuiz, 'message-wrapper--quiz-question-standalone' : questionInQuizStandalone, 'message-wrapper--quiz-question-answered' : questionAnswered}">
      <div class='message message--tutorreplyleft message--tutorreplyleft-images'>
        <div v-if="!questionInQuiz" class='message__whom'>MyTutor.chat:</div>
        <div v-html="chatLine.text"><br></div>
        <img v-for="(image, index) in chatLine.images" v-bind:key="index" v-bind:src="image" v-on:load="$root.$emit('image-loaded')"/>
      </div>
    </div>

    <div v-if="isImage" class='message-wrapper message-wrapper--dateandsubtitlemessage' v-bind:class="{'message-wrapper--quiz-question' : questionInQuiz, 'message-wrapper--quiz-question-standalone' : questionInQuizStandalone, 'message-wrapper--quiz-question-answered' : questionAnswered}">
      <div class='message message--tutorimageleft'>
        <div v-if="!questionInQuiz" class='message__whom'>MyTutor.chat:</div>
        <img v-bind:src="chatLine.images[0]" width='100%' v-on:load="$root.$emit('image-loaded')"/>
      </div>
    </div>

    <div v-if="isImageSlider" class='message-wrapper message-wrapper--dateandsubtitlemessage' v-bind:class="{'message-wrapper--quiz-question' : questionInQuiz, 'message-wrapper--quiz-question-standalone' : questionInQuizStandalone, 'message-wrapper--quiz-question-answered' : questionAnswered}">
      <div class='message message--tutorimageleft'>
        <div v-if="!questionInQuiz" class='message__whom'>MyTutor.chat:</div>
        <slider v-bind:images="chatLine.images" v-bind:parentID="chatLine.id"/>
      </div>
    </div>

    <div v-if="isUserText" class='message-wrapper'>
      <div class='message message--userreplyright'>
        <div class='message__whom'>{{user.getNameForChat()}}:</div>
        <div>{{chatLine.text}}</div>
      </div>
    </div>

    <div v-if="isNote" class='message-wrapper'>
      <div v-html="chatLine.text" class='message message--note'/>
    </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from "@aws-amplify/api";

// Generated queries & mutations
import { createSolutionView } from "../graphql/mutations";

// Visual components
import Slider from '../components/Slider.vue';

export default {
  name: 'ChatLine',
  components: {
    Slider
  },
  props: {
    chatLine: Object,
    user: Object,
    questionID: String,
    questionInQuiz: Boolean,
    questionInQuizStandalone: Boolean,
    questionAnswered: Boolean
  },
  data() {
    return {
      slideOptions: {
        rewind: true,
        width: '100%',
        perPage: 1,
        gap: '1rem',
      }
    }
  },
  computed: {
    isSubtitle: function() {
      return this.chatLine.logType === "SUBTITLE";
    },
    isMyTutorText: function() {
      return this.chatLine.logType === "MYTUTOR_TEXT" || this.chatLine.logType === "MYTUTOR_TEXT_AUTO_COMPLETE";
    },
    hasImages: function() {
      return this.chatLine.images !== null && this.chatLine.images.length > 0;
    },
    isImage: function() {
      return this.chatLine.logType === "MYTUTOR_FILE" && this.chatLine.images.length === 1;
    },
    isImageSlider: function() {
      return this.chatLine.logType === "MYTUTOR_FILE" && this.chatLine.images.length > 1;
    },
    isUserText: function() {
      return this.chatLine.logType === "USER_TEXT";
    },
    isNote: function() {
      return this.chatLine.logType === "NOTE";
    },
  },
  methods: {
    // ----------------------------------------------------------------------------
    // 
    // API calls
    //
    // ----------------------------------------------------------------------------
    logSolutionLoookedAtOnServer: async function(user, questionID, image) {
      console.log('logging that user ' + user.getEmail() + 'has viewed a solution image: ' + image);
      try {
        const item = {
            input: {
              userID: user.getID(),
              questionID: (questionID ? questionID : "unknown"), 
              solutionURL: image 
            }
        };
        const logSolutionViewRet = await API.graphql(graphqlOperation(createSolutionView, item));
        console.log(logSolutionViewRet);
        if (!logSolutionViewRet.data.createSolutionView) {
            console.log("Error logging solution view");
            this.logError("Logging solution view", "No data returned", true);     
            return null;
        }
      } catch (error) {
        this.logAPIError("Logging solution view", error, true);       
        return null;
      }
    },
    // ----------------------------------------------------------------------------
    // 
    // Chat behaviour
    //
    // ----------------------------------------------------------------------------
    logSolutionLookedAt: function(image) {
      if (this.user) {
        console.log("Slide moved for question: " + this.questionID);
        this.logSolutionLoookedAtOnServer(this.user, this.questionID, image);
      }
    }
  },
  mounted() {
    this.sliderSlidFn = (id, image) => {
      if (id === this.chatLine.id) {
        this.logSolutionLookedAt(image);
      }
    };
    this.$root.$on('slider-slid', this.sliderSlidFn);
  },
  beforeDestroy() {
    this.$root.$off("slider-slid", this.sliderSlidFn);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .message-wrapper {
      overflow: hidden; /* will contain if #first is longer than #second */
      margin: 0 10px 10px 10px;
      /* margin: 10px; */
      opacity: 1;
  }

  .message-wrapper--quiz-question {
    margin-bottom: 0;
    border: 4px solid #ffffff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 10px;
    border-bottom: none;
    background: #ffffff;
  }

  .message-wrapper--quiz-question-standalone {
    border-top-left-radius: 10px;
  }

  .message-wrapper--quiz-question-answered {
    border: 4px solid #5469F3;
    border-bottom: none;
  }

  .message-wrapper--subheading {
      margin: 0 0 20px 0;
      /* margin: 10px 0; */
  }

  .message-wrapper--dateandsubtitlemessage {
      /*padding-bottom: 35px;*/
  }

  .message {
      max-width: 70%;
      border-radius: 10px; 
      padding: 15px 15px 50px 15px;
      float:left;
      color: #1C1A4F;
      font-size: 16px;
      text-align: left; 
  }

  .message__whom {
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 5px;
  }

  .message--dateandsubtitlemessage {
      width: 100%;
      border-radius: 0px; 
      max-width: inherit;
      box-sizing: border-box;
      font-weight: bold;
      text-align: center; 
      color: #ffffff;
      background-color: #5469F3;
      font-size: 18px;
  }

  .message--tutorreplyleft {
      background-color: #ffffff;
  }

  .message--tutorreplyleft-images img {
    height: 144px;
    display: inline-block;
  }

  .message--tutorimageleft {
      width: 100%;
      max-width: inherit;
      box-sizing: border-box;
      background-color: #ffffff;
  }

  .message--tutorimageleft img {
      border: none;
  }

  .message--userreplyright {
      float:right;
      text-align: right; 
      background-color: #FCD174;
  }

  .message--note {
      width: 100%;
      max-width: inherit;
      box-sizing: border-box;
      background-color:#F3ADAB;
  }

  .message--quiz-question {
    border: 4px solid transparent;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }

  .message--quiz-question-answered {
    border: 4px solid #5469F3;
    border-bottom: none;
  }

  .splide__slide img {
    width : 100%;
    height: auto;
  }
  
  .splide__arrow {
    background: none;
  }

  .splide__arrow svg path {
    fill: #1C1A4F;
  }

  .splide__arrow--prev {
      left: 0;
  }

  .splide__arrow--next {
      right: 0;
  }

  .splide__pagination {
    bottom: -35px;
  }

  .splide__pagination__page {
    background-color: #B8BABD;
    margin-right: 5px;
  }

  .splide__pagination__page.is-active {
    transform: none;
    background-color: #1C1A4F;
  }

  @media screen and (orientation:landscape) and (max-height:640px) {
    /*.message--tutorimageleft {*/
    /*  width: 90%;*/
    /*}*/

    .splide__slide img {
      width : 100%;
    }
  }

  @media screen and (max-width:414px) {
    .message {
      padding: 10px;
    }
  }

  @media screen and (max-width:375px) {
    .message--tutorreplyleft-images img {
      height: 90px !important;
    }
  }
</style>
